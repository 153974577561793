import React, { useCallback, useEffect, useRef, useState } from "react";
import { BsQuestionCircle } from "react-icons/bs";
import { FaInfo, FaUserAstronaut } from "react-icons/fa";
import { GoDesktopDownload } from "react-icons/go";
import { IoMdLogOut } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { LuMenu } from "react-icons/lu";
import { RiMobileDownloadLine } from "react-icons/ri";
import { TbCalendarStats } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { Dialog, Loading, Popover, Toast } from "react-vant";
import axios from "../api/axiosConfig";
import { getUser, logOut } from "../utils/auth";
import Timeline from "./Timeline";
import WorkJournalBackground from "./WorkJournalBackground";

const BackgroundGraphic = () => (
	<svg className="-z-10 absolute inset-0 w-full h-full" xmlns="http://www.w3.org/2000/svg">
		<defs>
			<pattern id="grid" width="40" height="40" patternUnits="userSpaceOnUse">
				<path d="M 40 0 L 0 0 0 40" fill="none" stroke="#2A3B4C" strokeWidth="1" />
			</pattern>
		</defs>
		<rect width="100%" height="100%" fill="url(#grid)" />
	</svg>
);

const Workspace = () => {
	const [events, setEvents] = useState([]);
	const [working, setWorking] = useState(false);
	const [breakTime, setBreakTime] = useState(false);
	const [breakDuration, setBreakDuration] = useState(0);
	const [workStartTime, setWorkStartTime] = useState(null);
	const [timeDisplay, setTimeDisplay] = useState("loading");
	const [loadingScreen, setLoadingScreen] = useState(true);
	const [lastSessionDetails, setLastSessionDetails] = useState(null);
	const [moreInfo, setMoreInfo] = useState(false);
	const username = getUser() || null;
	const navigate = useNavigate();

	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);
	const intervalRef = useRef(null);

	const [deferredPrompt, setDeferredPrompt] = useState(null);

	useEffect(() => {
		window.addEventListener('beforeinstallprompt', (e) => {
			e.preventDefault();
			setDeferredPrompt(e);
		});
		return () => window.removeEventListener('beforeinstallprompt', () => { });
	}, []);

	const handleInstall = async () => {
		if (deferredPrompt) {
			deferredPrompt.prompt();
			const { outcome } = await deferredPrompt.userChoice;
			if (outcome === 'accepted') {
				setDeferredPrompt(null);
			}
		}
	};

	useEffect(() => {
		if (working && !breakTime) {
			intervalRef.current = setInterval(() => {
				setSeconds((prevSec) => {
					if (prevSec === 59) {
						setMinutes((prevMin) => {
							if (prevMin === 59) {
								setHours((prevHours) => prevHours + 1);
								return 0;
							}
							return prevMin + 1;
						});
						return 0;
					}
					return prevSec + 1;
				});
			}, 1000);
		} else {
			clearInterval(intervalRef.current);
		}

		return () => clearInterval(intervalRef.current);
	}, [working, breakTime]);

	const convertTime = useCallback(
		(time) => {
			const givenTimestamp = time;
			const now = new Date();
			const givenDate = new Date(givenTimestamp);
			const subBreak = givenDate.getTime() + breakDuration * 1000; // adding break duration
			const differenceInMilliseconds = subBreak - now.getTime();
			const absoluteDifference = Math.abs(differenceInMilliseconds);
			const differenceInSeconds = Math.floor(absoluteDifference / 1000);
			const differenceInMinutes = Math.floor(differenceInSeconds / 60);
			const differenceInHours = Math.floor(differenceInMinutes / 60);
			setHours(differenceInHours);
			setMinutes(differenceInMinutes % 60);
			setSeconds(differenceInSeconds % 60);
		},
		[breakDuration]
	);

	const calculateLastSessionDetails = useCallback((events) => {
		let sessionStart = null;
		let sessionEnd = null;
		let totalBreakDuration = 0;
		let be = null;

		const reversedEvents = events;
		for (let i = 0; i < reversedEvents.length; i++) {
			if (reversedEvents[i].type === "end_work") {
				sessionEnd = new Date(reversedEvents[i].timestamp);
			}
			if (reversedEvents[i].type === "end_break") {
				be = new Date(reversedEvents[i].timestamp).getTime();
			}
			if (reversedEvents[i].type === "start_break" && be) {
				const bs = new Date(reversedEvents[i].timestamp).getTime();
				totalBreakDuration += (be - bs) / 1000;
				be = null;
			}
			if (reversedEvents[i].type === "start_work") {
				sessionStart = new Date(reversedEvents[i].timestamp);
				break;
			}
		}

		if (sessionStart && sessionEnd) {
			const totalWorkDuration = (sessionEnd.getTime() - sessionStart.getTime()) / 1000;
			const effectiveWorkDuration = totalWorkDuration - totalBreakDuration;

			setLastSessionDetails({
				totalWorkDuration: formatDuration(totalWorkDuration),
				effectiveWorkDuration: formatDuration(effectiveWorkDuration),
				totalBreakDuration: formatDuration(totalBreakDuration)
			});
		}
	}, []);

	const formatDuration = (duration) => {
		const hours = Math.floor(duration / 3600);
		const minutes = Math.floor((duration % 3600) / 60);
		const seconds = duration % 60;
		return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toFixed().toString().padStart(2, "0")}`;
	};

	const determineCurrentState = (events) => {
		let lastWorkEvent = null;
		let lastBreakEvent = null;
		let bs = null;
		let be = null;
		let totalBreakDuration = 0;

		const currentSessionEvents = [];
		let sessionStarted = false;
		for (let i = 0; i < events.length; i++) {
			if (events[i].type === "start_work" || events[i].type === "start_break" || events[i].type === "end_break") {
				sessionStarted = true;
				currentSessionEvents.push(events[i]);
			} else if (events[i].type === "end_work") {
				sessionStarted = false;
				currentSessionEvents.push(events[i]);
				break;
			} else if (sessionStarted) {
				currentSessionEvents.push(events[i]);
			}
		}

		for (let i = 0; i < currentSessionEvents.length; i++) {
			if (currentSessionEvents[i].type === "start_break") {
				bs = new Date(currentSessionEvents[i].timestamp).getTime();
			}
			if (currentSessionEvents[i].type === "end_break") {
				be = new Date(currentSessionEvents[i].timestamp).getTime();
			}
			if (bs && be) {
				const breakDuration = (be - bs) / 1000;
				totalBreakDuration += breakDuration;
				bs = null;
			}
		}

		setBreakDuration(totalBreakDuration);

		for (let i = 0; i < currentSessionEvents.length; i++) {
			if (currentSessionEvents[i].type === "start_work" || currentSessionEvents[i].type === "end_work") {
				lastWorkEvent = currentSessionEvents[i];
				setWorkStartTime(currentSessionEvents[i].timestamp);
				break;
			}
		}

		for (let i = 0; i < currentSessionEvents.length; i++) {
			if (currentSessionEvents[i].type === "start_break" || currentSessionEvents[i].type === "end_break") {
				lastBreakEvent = currentSessionEvents[i];
				break;
			}
		}

		if (lastWorkEvent) {
			setWorking(lastWorkEvent.type === "start_work");
		}

		if (lastBreakEvent) {
			setBreakTime(lastBreakEvent.type === "start_break");
		}
	};

	const fetchData = useCallback(() => {
		const today = new Date().toISOString().split("T")[0];
		axios
			.get("/timeline.php", { params: { key: "username", value: username, date: today } })
			.then((response) => {
				const eventsData = response.data.reverse();
				setEvents(eventsData);
				determineCurrentState(eventsData);
				calculateLastSessionDetails(eventsData);
			})
			.catch((error) => {
				console.error("Error fetching timeline data:", error);
			})
			.finally(() => setLoadingScreen(false));
	}, [username, determineCurrentState, calculateLastSessionDetails]);


	useEffect(() => {
		if (!username) {
			navigate("/login");
		} else {
			fetchData();
		}
	}, [username, navigate, calculateLastSessionDetails]);

	useEffect(() => {
		if (workStartTime) {
			convertTime(workStartTime);
		}
	}, [breakDuration, workStartTime, convertTime]);

	useEffect(() => {
		if (breakTime) {
			setTimeDisplay("paused");
		} else if (!working) {
			setTimeDisplay("stopped");
		} else if (working && !breakTime) {
			setTimeDisplay("active");
		}
	}, [breakTime, working]);

	const handleEvent = (type, description = "") => {
		const event = {
			username: getUser(),
			type,
			description,
			timestamp: new Date().toISOString()
		};

		if (type === "add_description" && (description === null || description.length < 5)) {
			return 0;
		}

		axios
			.post("/timeline.php", event)
			.then((response) => {
				const newEvents = [event, ...events];
				setEvents(newEvents);
				determineCurrentState(newEvents);
				calculateLastSessionDetails(newEvents);
			})
			.catch((error) => {
				console.error("Error adding event:", error);
			});
	};

	const handleLogout = () => {
		logOut();
		Toast.fail({ message: "Logged Out", position: "bottom" });
		navigate("/");
	};

	const handleMoreInfo = () => {
		if (lastSessionDetails) {
			setMoreInfo(true);
		} else {
			Toast.fail({ message: "Your work hasn't ended yet", className: "text-break" });
		}
	};

	const [menuState, setMenuState] = useState("close");

	const recalculateTimer = useCallback(() => {
		if (workStartTime) {
			convertTime(workStartTime);
		}
	}, [workStartTime, convertTime]);

	// useEffect(() => {
	// 	let pageVisible = true;

	// 	const handleVisibilityChange = () => {
	// 		if (document.hidden) {
	// 			pageVisible = false;
	// 		} else {
	// 			pageVisible = true;
	// 			fetchData();
	// 		}
	// 	};

	// 	const handleFocus = () => {
	// 		pageVisible = true;
	// 		fetchData();
	// 	};

	// 	const handleBlur = () => {
	// 		pageVisible = false;
	// 	};

	// 	document.addEventListener("visibilitychange", handleVisibilityChange);
	// 	window.addEventListener('focus', handleFocus);
	// 	window.addEventListener('blur', handleBlur);

	// 	return () => {
	// 		document.removeEventListener("visibilitychange", handleVisibilityChange);
	// 		window.removeEventListener('focus', handleFocus);
	// 		window.removeEventListener('blur', handleBlur); 
	// 	};
	// }, [fetchData]);

	useEffect(() => {
		window.onfocus = recalculateTimer;

		return () => {
			window.onfocus = null;
		};
	}, [recalculateTimer]);

	return (
		<>
			<WorkJournalBackground />
			{loadingScreen && (
				<div className="loading">
					<Loading type="spinner" size={50} color="#fff" className="mx-auto" />
				</div>
			)}

			{moreInfo && (
				<Dialog visible={moreInfo} showConfirmButton={false} className="bg-transparent text-center" closeable={true} title="More Info" onClickCloseIcon={() => setMoreInfo(false)} closeOnClickOverlay={true}>
					<div className="work-info">
						<p className="rounded-4 px-2">
							You Worked for : <span>{lastSessionDetails.effectiveWorkDuration}</span>
							<br />
							Total Duration : <span>{lastSessionDetails.totalWorkDuration}</span>
							<br />
							Break Duration : <span>{lastSessionDetails.totalBreakDuration}</span>
							<br />
						</p>
					</div>
				</Dialog>
			)}

			<div className="workspace-container container pb-0">
				<div className="position-absolute top-0 end-0 me-4 mt-2 d-flex gap-3">
					<Popover
						placement="bottom"
						onOpen={() => setMenuState("open")}
						onClose={() => setMenuState("close")}
						className="bg-transparent-noblur"
						overlay={true}
						closeOnClickOverlay={true}
						reference={
							<div className="fs-4">
								{menuState === "close" && <LuMenu />}
								{menuState === "open" && <IoClose />}
							</div>
						}
					>
						<>
							<button className="bg-light text-dark border-0 my-2 fs-4 p-2 d-flex rounded-5" onClick={() => navigate("/stats")}>
								<TbCalendarStats />
							</button>
							<button className="bg-light text-dark border-0 mb-2 fs-4 p-2 d-flex rounded-5" onClick={() => handleMoreInfo()}>
								<FaInfo />
							</button>
							<button className="bg-light text-dark border-0 mb-2 fs-4 p-2 d-flex rounded-5" onClick={() => Toast.info({ message: `Current User : ${username}`, className: "fs-4" })}>
								<FaUserAstronaut />
							</button>
							{deferredPrompt && (
								<button className="bg-light text-dark border-0 mb-2 fs-4 p-2 d-flex rounded-5 cursor-pointer" onClick={handleInstall}>
									<GoDesktopDownload className="max-sm:hidden" />
									<RiMobileDownloadLine className="sm:hidden" />
								</button>
							)}
							<button className="bg-dark text-light border-0 mb-2 fs-4 p-2 d-flex rounded-5" onClick={() => handleLogout()}>
								<IoMdLogOut />
							</button>
						</>
					</Popover>
				</div>
				<div className="timer mb-3">
					{timeDisplay === "active" && (
						<>
							<p className="col rounded-4 rounded-start-0 px-2 ps-1">
								{hours.toString().padStart(2, "0")} : {minutes.toString().padStart(2, "0")} : {seconds.toString().padStart(2, "0")}
							</p>
						</>
					)}
					{timeDisplay === "loading" && (
						<>
							<Loading type="spinner" className="bg-dark rounded-4 p-2" />
						</>
					)}
					{timeDisplay === "paused" && (
						<>
							<p className="bg-info rounded-4 px-2"> Paused </p>
						</>
					)}
					{timeDisplay === "stopped" && lastSessionDetails && (
						<>
							<div className="work-info">
								<p className="rounded-4 px-2">
									You have Worked For <span>{lastSessionDetails.effectiveWorkDuration}</span>, excluding <span>{lastSessionDetails.totalBreakDuration}</span> of Break time.
									<BsQuestionCircle
										onClick={() => {
											setMoreInfo(true);
										}}
									/>
								</p>
							</div>
						</>
					)}
				</div>

				<div className="actions">
					{!working && !breakTime && (
						<button className="start-work" onClick={() => handleEvent("start_work")}>
							Start Work
						</button>
					)}
					{working && !breakTime && (
						<button className="start-break" onClick={() => handleEvent("start_break")}>
							Start Break
						</button>
					)}
					{working && (
						<button className="add-description" onClick={() => handleEvent("add_description", prompt("Enter description:"))}>
							Add Description
						</button>
					)}
					{working && !breakTime && (
						<button className="end-work" onClick={() => handleEvent("end_work")}>
							End Work
						</button>
					)}
					{breakTime && (
						<button className="end-break" onClick={() => handleEvent("end_break")}>
							End Break
						</button>
					)}
				</div>
				<Timeline events={events} />
				<div className="text-center text-secondary version">
					<p>Version {process.env.REACT_APP_VERSION}</p>
				</div>
			</div>
		</>
	);
};

export default Workspace;
