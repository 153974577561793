import React, { useEffect, useRef } from "react";

const WorkJournalBackground = () => {
    const canvasRef = useRef(null);
    const mouseRef = useRef({ x: 0, y: 0 });

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        let animationFrameId;

        // Handle mouse movement
        const handleMouseMove = (event) => {
            mouseRef.current = {
                x: event.clientX,
                y: event.clientY
            };
        };

        // Set canvas size
        const resizeCanvas = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        };

        resizeCanvas();
        window.addEventListener("resize", resizeCanvas);
        window.addEventListener("mousemove", handleMouseMove);

        class Particle {
            constructor() {
                this.reset();
                // Random starting position
                this.x = Math.random() * canvas.width;
                this.y = Math.random() * canvas.height;
                // Store original position for oscillation
                this.baseX = this.x;
                this.baseY = this.y;
                // Random oscillation offset
                this.offset = Math.random() * Math.PI * 2;
            }

            reset() {
                // Subtle movement
                this.speedX = Math.random() * 0.5 - 0.25;
                this.speedY = Math.random() * 0.5 - 0.25;
                this.size = Math.random() * 2 + 0.5;
                this.opacity = Math.random() * 0.5 + 0.2;
                this.hue = Math.random() * 30 - 15; // Slight color variation
            }

            update() {
                // Oscillate around base position
                const time = Date.now() / 2000;
                this.x = this.baseX + Math.sin(time + this.offset) * 50;
                this.y = this.baseY + Math.cos(time + this.offset) * 50;

                // Move base position slowly
                this.baseX += this.speedX;
                this.baseY += this.speedY;

                // Check boundaries and reset if needed
                if (this.baseX < 0 || this.baseX > canvas.width ||
                    this.baseY < 0 || this.baseY > canvas.height) {
                    this.baseX = Math.random() * canvas.width;
                    this.baseY = Math.random() * canvas.height;
                    this.reset();
                }

                // React to mouse proximity
                const dx = mouseRef.current.x - this.x;
                const dy = mouseRef.current.y - this.y;
                const distance = Math.sqrt(dx * dx + dy * dy);
                if (distance < 100) {
                    this.x += dx * 0.02;
                    this.y += dy * 0.02;
                }
            }

            draw() {
                ctx.beginPath();
                ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
                ctx.fillStyle = `hsla(220, 70%, 50%, ${this.opacity})`;
                ctx.fill();
            }
        }

        const particles = Array.from({ length: 50 }, () => new Particle());

        const drawConnections = () => {
            ctx.strokeStyle = 'rgba(100, 150, 255, 0.05)';
            ctx.lineWidth = 0.5;

            for (let i = 0; i < particles.length; i++) {
                for (let j = i + 1; j < particles.length; j++) {
                    const dx = particles[i].x - particles[j].x;
                    const dy = particles[i].y - particles[j].y;
                    const distance = Math.sqrt(dx * dx + dy * dy);

                    if (distance < 150) {
                        ctx.beginPath();
                        ctx.moveTo(particles[i].x, particles[i].y);
                        ctx.lineTo(particles[j].x, particles[j].y);
                        ctx.stroke();
                    }
                }
            }
        };

        const animate = () => {
            // Create gradient background
            const gradient = ctx.createRadialGradient(
                canvas.width / 2,
                canvas.height / 2,
                0,
                canvas.width / 2,
                canvas.height / 2,
                canvas.width / 2
            );
            gradient.addColorStop(0, '#1e4b7b');
            gradient.addColorStop(1, '#000913');

            // Clear and fill background
            ctx.fillStyle = gradient;
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Update and draw particles
            particles.forEach(particle => {
                particle.update();
                particle.draw();
            });

            // Draw connections
            drawConnections();

            animationFrameId = requestAnimationFrame(animate);
        };

        animate();

        return () => {
            window.removeEventListener("resize", resizeCanvas);
            window.removeEventListener("mousemove", handleMouseMove);
            cancelAnimationFrame(animationFrameId);
        };
    }, []);

    return (
        <canvas
            ref={canvasRef}
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: -1,
                opacity: 0.8,
            }}
        />
    );
};

export default WorkJournalBackground;
