import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import {
    PlayArrow,
    Pause,
    Stop,
    Edit
} from '@mui/icons-material';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import { format } from 'date-fns';

const TimelineItem = ({ event }) => {
    const getEventIcon = (type) => {
        switch (type) {
            case 'start_work':
                return <WorkOutlineIcon sx={{ color: 'success.main' }} />;
            case 'start_break':
                return <LunchDiningIcon sx={{ color: 'warning.main' }} />;
            case 'end_break':
                return <LunchDiningIcon sx={{ color: 'success.main' }} />;
            case 'end_work':
                return <WorkOffIcon sx={{ color: 'error.main' }} />;
            case 'add_description':
                return <Edit sx={{ color: 'info.main' }} />;
            default:
                return null;
        }
    };

    const getEventColor = (type) => {
        switch (type) {
            case 'start_work':
                return 'success.dark';
            case 'start_break':
                return 'warning.dark';
            case 'end_break':
                return 'success.dark';
            case 'end_work':
                return 'error.dark';
            case 'add_description':
                return 'info.dark';
            default:
                return 'grey.700';
        }
    };

    const getEventTitle = (type) => {
        switch (type) {
            case 'start_work':
                return 'Started Working';
            case 'start_break':
                return 'Started Break';
            case 'end_break':
                return 'Ended Break';
            case 'end_work':
                return 'Ended Work';
            case 'add_description':
                return 'Added Note';
            default:
                return type;
        }
    };

    return (
        <Box sx={{ 
            display: 'flex', 
            alignItems: 'flex-start',
            gap: 2,
            mb: 2,
            position: 'relative'
        }}>
            {/* Time on the left */}
            <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                    minWidth: '80px',
                    textAlign: 'right',
                    pt: 1
                }}
            >
                {format(new Date(event.timestamp), 'HH:mm:ss')}
            </Typography>

            {/* Center line with dot */}
            <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative'
            }}>
                <Box sx={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    // bgcolor: getEventColor(event.type),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 2
                }}>
                    {getEventIcon(event.type)}
                </Box>
                <Box sx={{
                    position: 'absolute',
                    top: '30px',
                    width: '2px',
                    height: 'calc(100% + 16px)',
                    bgcolor: 'grey.800',
                    zIndex: 1
                }} />
            </Box>

            {/* Content on the right */}
            <Paper
                elevation={2}
                sx={{
                    p: 2,
                    flex: 1,
                    bgcolor: 'background.paper',
                    borderLeft: 3,
                    borderColor: getEventColor(event.type),
                    '&:hover': {
                        transform: 'translateX(4px)',
                        transition: 'transform 0.2s'
                    }
                }}
            >
                <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
                    {getEventTitle(event.type)}
                </Typography>
                {event.description && (
                    <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ 
                            mt: 1,
                            px: 1,
                            py: 0.5,
                            bgcolor: 'background.default',
                            borderRadius: 1
                        }}
                    >
                        {event.description}
                    </Typography>
                )}
            </Paper>
        </Box>
    );
};

const Timeline = ({ events }) => {
    return (
        <Paper
            elevation={3}
            sx={{
                maxHeight: '60vh',
                width: '100%',
                overflow: 'auto',
                p: 3,
                backdropFilter: 'blur(3px)',
                bgcolor: 'transparent',
                '&::-webkit-scrollbar': {
                    width: '8px'
                },
                '&::-webkit-scrollbar-track': {
                    bgcolor: 'background.paper'
                },
                '&::-webkit-scrollbar-thumb': {
                    bgcolor: 'grey.800',
                    borderRadius: '4px'
                }
            }}
        >
            {events.map((event, index) => (
                <TimelineItem key={index} event={event} />
            ))}
        </Paper>
    );
};

export default Timeline;

